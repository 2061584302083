import { reactionOptions } from "components/feed/FeedCard/base/reactions";

import { getBaseActivityWithReactionsStub } from "./stubs";

// TODO: fix, this doesn't work for all feed items, specifically failing on expert panels when attempting to fetch previous comments
// Gets the id for a group of aggregated activities (unrelated to the Groups feature)
export function getAggregatedActivityGroupId({
  foreignId,
  object,
  time,
  verb,
}: {
  foreignId: string;
  object: { foreign_id: string };
  time: string;
  verb: string;
}): string {
  if (verb === "expertpanel") return `${verb}_SO:${object.foreign_id}`;

  return `${verb}_SO:${verb}:${foreignId}__${time.split("T")[0]}`;
}

function updateAggregatedActivity({ feed, activity, newData }) {
  return feed.map((a) => (a.id === activity.id ? { ...a, ...newData } : a));
}

function updateGroupEPFeedActivity({ feed, activity, newData }) {
  return feed?.map((act) => (act?.id === activity.id ? newData : act));
}

export function updateFeedActivity({ activity, feed, newData, queryKey }) {
  if (queryKey.startsWith(`activity:`)) {
    if (feed.activities) {
      return {
        ...feed,
        activities: feed.activities.with(0, { ...activity, ...newData }),
      };
    }

    return { ...activity, ...newData };
  }

  if (queryKey?.startsWith("group-eps-")) {
    return updateGroupEPFeedActivity({ feed, activity, newData });
  }
  if (!feed?.pages) {
    return {
      ...activity,
      ...newData,
    };
  }

  return {
    ...feed,
    pages: feed?.pages?.map((page) => {
      return {
        ...page,
        results: page?.results.map((a) => {
          // Aggregated feed
          if (a.activities) {
            const aggregatedActivityGroupId = getAggregatedActivityGroupId({
              foreignId: activity.foreign_id,
              object: activity.object,
              time: activity.time,
              verb: activity.verb,
            });
            if (a.group === aggregatedActivityGroupId) {
              return {
                ...a,
                activities: updateAggregatedActivity({
                  feed: a.activities,
                  activity,
                  newData,
                }),
              };
            }
            return a;
          }

          // Flat feed
          return a.id === activity.id ? { ...a, ...newData } : a;
        }),
      };
    }),
  };
}

export function deleteActivity({ feedData, activityId }) {
  const updatedPages = feedData?.pages?.map((page) => {
    return {
      ...page,
      results: page?.results?.filter((activity) => activity.id !== activityId),
    };
  });
  return {
    ...feedData,
    pages: updatedPages,
  };
}

export function filterOutExistingActivities({ feedData, activities }) {
  const newActivities = activities.filter(
    (activity) => !feedData.pages[0]?.results.some((a) => a.id === activity.id)
  );
  return newActivities;
}

export function addPinActivity({ feedData = [], activity }) {
  return [
    ...feedData,
    {
      ...activity,
      order: (feedData?.length ?? 0) + 1,
    },
  ];
}

export function addReactionsStubToSubscribedActivities(activities) {
  return activities.map((activity) => {
    return {
      ...getBaseActivityWithReactionsStub(),
      ...activity,
    };
  });
}

export function removePinActivity({ feedData, activity }) {
  return feedData.filter(
    (feedDataActivity) => feedDataActivity.id !== activity.id
  );
}

export function prependFeedActivities({ feedData, activities }) {
  const filteredNewActivities = filterOutExistingActivities({
    feedData,
    activities,
  });

  const updatedPages = feedData?.pages?.map((page, i) => {
    if (i === 0) {
      return {
        ...page,
        results: [...filteredNewActivities, ...page.results],
      };
    }

    return page;
  });

  return {
    ...feedData,
    pages: updatedPages,
  };
}

export function removePendingFeedActivities({ feedData }) {
  const updatedPages = feedData?.pages?.map((page, i) => {
    if (i === 0) {
      return {
        ...page,
        results: page.results.filter(
          (activity) => !activity.id.startsWith("pending:")
        ),
      };
    }

    return page;
  });

  return {
    ...feedData,
    pages: updatedPages,
  };
}

export function removePendingResponses({ activity, type }) {
  const latestKey = activity.latest_children
    ? "latest_children"
    : "latest_reactions";

  activity[latestKey][type] = activity?.[latestKey]?.[type]?.filter(
    (response) => !response.id.startsWith("pending:")
  );
  if (activity.own_reactions?.[type]) {
    activity.own_reactions[type] = activity.own_reactions[type].filter(
      (response) => !response.id.startsWith("pending:")
    );
  }

  return activity;
}

export function removePendingReactions(activity) {
  const latestKey = activity.latest_children
    ? "latest_children"
    : "latest_reactions";
  const kinds = Object.keys(activity[latestKey]);

  kinds.forEach((kind) => {
    activity[latestKey][kind] = activity[latestKey][kind].filter(
      (reaction) => !reaction.id.startsWith("pending:")
    );
  });

  return activity;
}

export function parseReactions({ activity, myId }) {
  const { children_counts, latest_children } = activity;
  const reactions = { counts: {}, latest: {}, own: undefined };
  Object.keys(children_counts).forEach((key) => {
    if (reactionOptions[key] && children_counts[key]) {
      reactions.counts[key] = children_counts[key];
      reactions.latest[key] = latest_children[key];
      const ownReaction = latest_children[key].find((r) => r.user.id === myId);
      if (ownReaction) reactions.own = ownReaction;
    }
  });

  return reactions;
}
